import { CL_FETCH_CONTRACT_AND_PROVIDERS, CL_FETCH_CASELOAD, CL_FETCH_EVENTS, CL_FETCH_EXCEPTION_REPORT, CL_RESET_EXCEPTION_REPORT, CL_FETCH_KEYWORKERS, CL_FETCH_ORG_DATA, CLEAR_CASELOAD} from "../actions/types";
import { Logger, Cache } from "aws-amplify";
import _ from "lodash";

/* eslint-disable */
const logger = new Logger("CASELOAD.REDUCER", "DEBUG");
/* eslint-enable */

export const caseloadReducer = (state = {}, action) => {
  switch (action.type) {

    case CL_FETCH_KEYWORKERS: {
      return {
        ...state,
        keyworkers: action.data
      }
    }

    case CL_FETCH_ORG_DATA: {
      let providers = [];
      _.each(action.data.providers, (provider) => {
          let prov = provider.sort;
          providers.push({
              id: prov,
              name: provider.name
          });
      });
      ["exceptionReport", "kwReport", "fullReport"].forEach(k => Cache.removeItem(k))
      return {
        ...state,
        providers: providers,
        contracts: action.data.contracts,
        keyworkers: action.data.keyworkers,
        organisation: action.data.organisation
      }
    }
    
    case CL_FETCH_CASELOAD: {
      if(action.caseloadType === "overall"){
        return {
          ...state,
          data: {
            caseload: {
              "invited":action.data.caseload.invited,
              "accessed":action.data.caseload.accessed,
              "initialAssessmentComplete":action.data.caseload.initial,
              "reassessmentIssued":action.data.caseload["reAssessment-issued"],
              "reassessmentComplete":action.data.caseload.reAssessment,
              "jobviewed": action.data.caseload.jobviewed,
              "jobApplied": action.data.caseload.jobApplied,
              "qualviewed": action.data.caseload.qualviewed,
              "qualApplied": action.data.caseload.qualApplied,
              "resourceViewed": action.data.caseload.resourceViewed,
              "courseViewed": action.data.caseload.courseViewed,
              "skillValidated": action.data.caseload.skillValidated,
              "cvCreated": action.data.caseload.cvCreated,
              "skillsAdded": action.data.caseload.skillsAdded
            },
            inactive: action.data.live.inactive,
            active: action.data.live.active
          },
          exceptionReport: null
        }
      }
      else{
        return {
          ...state,
          dataBreakdown: {
            caseload: {
              "invited":action.data.caseload.invited,
              "accessed":action.data.caseload.accessed,
              "initialAssessmentComplete":action.data.caseload.initial,
              "reassessmentIssued":action.data.caseload["reAssessment-issued"],
              "reassessmentComplete":action.data.caseload.reAssessment,
              "jobviewed": action.data.caseload.jobviewed,
              "jobApplied": action.data.caseload.jobApplied,
              "qualviewed": action.data.caseload.qualviewed,
              "qualApplied": action.data.caseload.qualApplied,
              "resourceViewed": action.data.caseload.resourceViewed,
              "courseViewed": action.data.caseload.courseViewed,
              "skillValidated": action.data.caseload.skillValidated,
              "cvCreated": action.data.caseload.cvCreated,
              "skillsAdded": action.data.caseload.skillsAdded
            },
            inactive: action.data.live.inactive,
            active: action.data.live.active
          },
          exceptionReport: null
        }  
      } 
    }

    case CLEAR_CASELOAD: {
      if(action.caseloadType === "overall"){
        return {
          ...state,
          data: {
            caseload:null,
            inactive:null,
            active:null
          }
        }
      }
      else{
        return {
          ...state,
          dataBreakdown: {
            caseload:null,
            inactive:null,
            active:null
          }
        }
      }     
    }

    case CL_FETCH_CONTRACT_AND_PROVIDERS: {

        let providers = [];
        _.each(action.providers, (provider) => {
            let prov = provider.sort;
            providers.push({
                id: prov,
                name: provider.name
            });
        });
        ["exceptionReport", "kwReport", "fullReport"].forEach(k => Cache.removeItem(k))
        return {
          ...state,
          providers: providers,
          contracts: action.contracts,
          organisation: action.organisation,
          data: action.data,
          exceptionReport: null
        }
    }
    case CL_FETCH_EVENTS: 
      let events = null
      if (action.data){
        events = action.data.map(item => {
          const userKW = action.keyworkers.find(element => item.keyworkerID === element.userID)
          return {
            ...item,
            keyworkerName: userKW.name,
            keyworkerEmail: userKW.email ? userKW.email : "No email"
          }
        })
      }
      
      return {
        ...state,
        userEvents: events
        
      }

    case CL_FETCH_EXCEPTION_REPORT: 
      if (action.data.file){
        const reportPath = Cache.getItem(action.reportType)
        if (action.reportType && !reportPath){
          Cache.setItem(action.reportType, action.data.file, {
            expires: new Date().getTime() + 300000
          });
        }
        
        return {
          ...state,
          exceptionReport: action.data.file
        }
      }
      else{
        return {
          ...state,
          exceptionReport: "failed"
        }
      }

      case CL_RESET_EXCEPTION_REPORT: 
        return {
          ...state,
          exceptionReport: null
        }      

    default:
      return state;
  }


};

export const DEFAULT = "/";
export const LOGIN = "/login";
export const FORGET = "/forget";
export const MAIN = "/portal";
export const PRINT = "/print";
export const PROFILE = "/portal/profile";
export const FAQ  = "/portal/faq";
export const PRICING = "/pricing";
export const CANDIDATES = "/portal/candidates";
export const SKILLZMINER = "/portal/skillzminer";//not use?
export const SKILLZMINER_RESULTS = "/portal/skillzminer/job-results";//not use?
export const VACANCIES = "/portal/vacancies";
export const DASHBOARDS = "/portal/dashboards";//not use?

export const INSIGHTS = "/portal/insights"; //not use?
export const CASELOADS = "/portal/caseloads";

//SUPER ADMIN
export const SUPER_ADMIN = "/portal/super-admin";
export const SUPER_ADMIN_FEEDBACK = "/portal/super-admin/feedback";
export const SUPER_ADMIN_FEEDBACK_SKILLS = "/portal/super-admin/skillsasessmentfeedbacks";
export const SUPER_ADMIN_BRANDING = "/portal/super-admin/branding";
export const SUPER_ADMIN_PROVIDERS = "/portal/super-admin/providers";
export const SUPER_ADMIN_CONTRACTS = "/portal/super-admin/contracts";
export const SUPER_ADMIN_OAUSERS = "/portal/super-admin/oausers";
export const SUPER_ADMIN_CASELOAD = "/portal/super-admin/caseload";
export const SUPER_ADMIN_CREDITS = "/portal/super-admin/credits";
export const SUPER_ADMIN_COINS = "/portal/super-admin/coins";

//ADMIN stuff

export const ADMIN = "/portal/admin";
export const QUALIFICATIONS = '/portal/admin/qualifications';
export const MANAGE_USER = '/portal/admin/users';
export const NEW_USER = '/portal/admin/createuser';
export const ORGANISATION = '/portal/admin/organisation';
export const CONTRACTS = '/portal/admin/contracts';
export const INTERVENTIONS = '/portal/admin/interventions';
export const PROVIDERS = '/portal/admin/providers';
export const BULK_INVITE = '/portal/admin/bulkinvite';

//HR manager/Line Manager
export const ROLES = '/portal/admin/roles';
export const DEPARTMENTS = '/portal/admin/departments';
export const DEMOGRAPHICS = '/portal/admin/demographics';
export const LINEMANAGERS = '/portal/admin/linemanagers';

//KEYWORKER MOSTLY
export const NEW_CANDIDATE = "/portal/admin/createcandidate";
export const MANAGE_CANDIDATES = "/portal/admin/candidates";